<template>
  <div v-if="loaded" class="PanoFullScreen">
    <Pano :baseData="{form}" :skinData="skinData" @changePano="changePano"></Pano>
  </div>
  <div v-else>
    <i class="fas fa-spinner fa-pulse"></i>
    加载中…
  </div>
</template>
<script>
  import { Pano } from 'common'
  import Skin from 'common/src/components/PanoSkins/EditPublicPanoSkin.json'
  export default {
    scrollToTop: true,
    components: {
      Pano
    },
    data() {
      return {
        loaded: false,
        form: null,
        skinData: Skin
      }
    },
    computed: {
    },
    watch: {
    },
    created() {
    },
    mounted() {
      for (var i in this.skinData.sences) {
        this.skinData.sences[i].components.push({
          name: 'InnAdminUrls'
        })
      }
      this.loadData()
    },
    destroyed() {
    },
    methods: {
      changePano(pano) {
        this.pano = pano
      },
      loadData() {
        this.$axios.get('/Api/PublicAdmin/Panos/' + this.$route.params.panoId)
          .then((response) => {
            let val = response.data
            if (val.title == '无标题') val.title = null;
            this.form = val;
            this.loaded = true;
          })
          .catch((error) => {
            console.error(error)
          })
      },
    },
  }
</script>
<style scoped>
  .PanoFullScreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1050;
  }
</style>
